import React, { useEffect } from 'react'
import { useState } from 'react'
import { setAuthToken } from './Auth/setAuthToken';
import { getDatabase, ref, onValue, } from "firebase/database";
import axios from 'axios';
export const contextAPI = React.createContext()

function Context({ children }) {
    let [prakritiresult, setPrakritiresult] = useState([])
    let [vikritiresult, setvikritiresult] = useState([])
    let [products, setProducts] = useState([])
    let [specialcoupon, setSpecialCoupon] = useState('')
    let [discountedCoupon, setDiscountCoupon] = useState([])
    let [address, setAddress] = useState()
    let [coupon, setcoupon] = useState()
    let [otp, setOtp] = useState('');
    let [Doctors, SetDoctors] = useState([])
    let [interests, setinterest] = useState([])
    let [disease, setDisease] = useState([])
    let [prakriti_data, setPrakriti] = useState([])
    let [vikriti_data, setvikriti] = useState([])
    let Token = localStorage.getItem('jwt-token')
    let [Services, setServices] = useState([])
    let [pranayama, setpranayama] = useState([])
    let [meditation, setmeditation] = useState([])
    let [lab_tests, setTest] = useState([])

    let getData = () => {
        try {
            const db = getDatabase();
            const Ref = ref(db, '/ProductsCategories');

            onValue(Ref, async (snapshot) => {
                try {
                    const data = await snapshot.val()
                    const filtered = Object.values(data).filter((e) => e.company_id !== '1007')
                    setProducts(filtered)
                }
                catch (error) {
                    console.log(error)
                }
            })

        }
        catch (error) {
            console.log(error)
        }
    }

    let getLabtest = () => {
        try {
            const db = getDatabase();
            const Ref = ref(db, `/Tests`);
            onValue(Ref, async (snapshot) => {
                try {
                    const data = await snapshot.val()
                    setTest(Object.values(data))

                }
                catch (error) {
                    console.log(error)
                }

            })

        }
        catch (error) {
            console.log(error)
        }
    }

    const getPranayama = () => {
        try {
            const db = getDatabase();
            const Ref = ref(db, `/pranayama`);
            onValue(Ref, async (snapshot) => {
                try {
                    const data = await snapshot.val()
                    setpranayama(Object.values(data))

                }
                catch (error) {
                    console.log(error)
                }

            })

        }
        catch (error) {
            console.log(error)
        }
    }
    const getMeditation = () => {
        try {
            const db = getDatabase();
            const Ref = ref(db, `/meditation`);
            onValue(Ref, async (snapshot) => {
                try {
                    const data = await snapshot.val()
                    setmeditation(Object.values(data))

                }
                catch (error) {
                    console.log(error)
                }

            })

        }
        catch (error) {
            console.log(error)
        }
    }


    let DiscountCoupon = () => {
        try {
            const db = getDatabase();
            const Ref = ref(db, `/shop/promocode`);
            onValue(Ref, async (snapshot) => {
                try {
                    const data = await snapshot.val()
                    setDiscountCoupon(Object.values(data))
                }
                catch (error) {
                    console.log(error)
                }

            })

        }
        catch (error) {
            console.log(error)
        }
    }

    let SpecialCoupon = async () => {
        try {
            const db = getDatabase();
            const Ref = ref(db, `/SpecialCoupon`);
            onValue(Ref, async (snapshot) => {
                try {
                    const data = await snapshot.val()
                    setSpecialCoupon(data)
                }
                catch (error) {
                    console.log(error)
                }

            })

        }
        catch (error) {
            console.log(error)
        }
    }



    useEffect(() => {
        getData()
        DiscountCoupon()
        SpecialCoupon()
        getPranayama()
        getMeditation()
        getLabtest()

    }, [])

    return <contextAPI.Provider value={{
        prakritiresult, setPrakritiresult, vikritiresult, setvikritiresult, products, otp, discountedCoupon, lab_tests, setcoupon, coupon, setAddress, address, Doctors, otp, pranayama, meditation, interests, disease, setOtp, SetDoctors, setinterest, setDisease, setvikriti, prakriti_data, setPrakriti, vikriti_data, specialcoupon
    }}>
        {children}
    </contextAPI.Provider>
}
export default Context;